<template>
    <div>
        <div class="legend">
            <div class="legend__item">
                <div class="square bg-red-600"></div>
                <p> - требуется доп.соглашение </p>
            </div>
            <div class="legend__item">
                <div class="square bg-yellow-300"></div>
                <p> - имеется доп.соглашение</p>
            </div>
        </div>
        <div v-if="exceeded" class="data-table__wrapper">
            <table class="data-table__table">
                <thead class="data-table__headings">
                <th class="data-table__heading"
                >№
                </th>
                <th class="data-table__heading" v-for="(header, idx) in headers"
                    :key="'header-'+header.value + '_'+ idx"
                >{{ header.name }}
                    <span v-if="!header.link"
                          class="inline-block mx-1"
                    >
                    </span>
                </th>


                </thead>
                <tbody class="data-table__body">
                <tr class="data-table__row"
                    v-for="(data, idx) in exceeded"
                    :key="'item-'+data.id"
                    @click="showModal(data.id)"
                >
                    <td class="p-3">{{ idx + 1 }}</td>
                    <td
                        :class="header.value === 'shipments' || header.value === 'sum' ? data.hasContractClass:''"
                        class="p-3 "
                        :key="header.value + '_'+ idx"
                        v-for="(header, idx) in headers"
                    >
                        <span>{{ data[header.value] || "—" }}</span>
                    </td>

                </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <h3 class="text-2xl text-center w-full p-10">
                Список компаний пуст пуст
                <g-emoji>😥</g-emoji>
            </h3>
        </div>
        <CreateAdditionalContract :showModal.sync="show" :id="selected"></CreateAdditionalContract>
    </div>
</template>

<script>

import CreateAdditionalContract from './ContractsExceededModal'
import {mapGetters} from "vuex";

export default {
    name: "ContractsExceeded",
    components: {
        CreateAdditionalContract
    },
    data: () => ({
        selected: null,
        show: false,
        headers: [
            {name: 'Пользователь', value: 'login'},
            {name: 'Контракт', value: 'uf_contract'},
            {name: 'Телефон пользователя', value: 'uf_contact_phone'},
            {name: 'Наименование юр.лица', value: 'uf_name'},
            {name: 'УНП', value: 'uf_unp'},
            {name: 'Страна юр.лица', value: 'uf_org_country'},
            {name: 'Платежи сумма', value: 'sum'},
            {name: 'Перевозки', value: 'shipments'},
        ]
    }),
    computed: {
        ...mapGetters({
            items: 'admin/exceeded'
        }),
        exceeded() {

            return this.items.map(i => {
                console.log(i)
                const hasContractClass = i.hasContract
                    ? {'bg-yellow-300': true, 'text-black': true}
                    : {'bg-red-600': true, 'text-white': true}

                console.log(hasContractClass)
                return {
                    ...i,
                    hasContractClass
                }
            })

        }
    },
    created() {
        this.$store.dispatch('admin/getExceeded')
    },

    methods: {
        showModal(id) {
            this.selected = id
            this.show = true
        }
    }
}
</script>

<style scoped>
.legend {
    @apply flex mx-1 my-5
}

.legend__item {
    @apply flex items-center mr-5
}

.square {
    @apply w-4 h-4 mr-3
}

.data-table__wrapper {
    @apply overflow-y-scroll max-h-128  shadow-md
}

.dark .data-table__wrapper {
    box-shadow: none;
}

.data-table__table {
    @apply w-full text-left table dark:text-gray-400  text-gray-700 border-separate space-y-6 text-sm
}

.data-table__headings {
    @apply dark:bg-gray-800 bg-white text-gray-500
}

.data-table__heading {
    @apply p-3 select-none
}

.data-table__body {
    @apply dark:text-gray-400 text-gray-600
}

.data-table__row {
    @apply dark:bg-gray-800 bg-white cursor-pointer dark:hover:bg-gray-700 hover:bg-gray-300
}

::-webkit-scrollbar {
    display: block;
    overflow: auto;
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: lightskyblue;
}
</style>