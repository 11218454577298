export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    let int = Math.floor(Math.random() * (max - min)) + min;
    return int
}
export  function isInt(n){
    return Number(n) % 1 === 0;
}
export function normalizeFloat({value = "", afterPoint}){
    if(!isInt(value)){ //Если не целое число
        const float = Number.parseFloat(value)
        if(afterPoint){ // Если указано точное кол-во символов после точки
            return float.toFixed(afterPoint).replace(/0*$/,"").replace(/\.*$/,"")
        }
        if(value >= 0.001){
            return float.toFixed(3).replace(/0*$/,"").replace(/\.*$/,"") // Убираем лишние нули в конце и если нужно точку
        }else{
            return float.toFixed(6).replace(/0*$/,"").replace(/\.*$/,"")
        }
    }
    return value
}

export function roundNumber(number, digits) {
    const  multiple = Math.pow(10, digits);
    return Math.round(number * multiple) / multiple;
}
