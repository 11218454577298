<template>
    <transition name="modal">
        <div tabindex="0"
             class="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50  transition-opacity flex justify-center items-center ">
            <div class="modal-wrapper h-full w-full flex justify-center items-center">
                <slot></slot>
            </div>
        </div>
    </transition>

</template>

<script>
export default {
    name: "ModalWindow",
    props:{
        show: {
            type:Boolean,

        }
    },
    watch: {
        show() {
            let body = document.getElementsByTagName('body')
            if (this.show === true) {
                body[0].style.overflowY = 'hidden'
            } else {
                body[0].style.overflowY = 'scroll'
            }
        }
    },
    created() {
        document.addEventListener('keyup', this.escape)
    },
    destroyed() {
        document.removeEventListener('keyup', this.escape)
    },
    methods:{
        escape() {
            if (event.keyCode === 27) {
                this.$emit('close')
            }
        },
    }
}
</script>

<style scoped>
.modal-wrapper::-webkit-scrollbar {
    display: none;
}

</style>